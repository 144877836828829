import img1 from './1.png'
import img2 from './2.png'
import img3 from './3.png'
import img4 from './4.png'
import img5 from './5.png'
import img6 from './6.png'
import img7 from './7.png'

export default [
  img1,
  img2,
  img3,
  img4,
  img5,
  img6,
  img7
]
